<template>
	<base-material-snackbar
    v-model="alert.active"
    :type="alert.type"
    bottom
    right
  >
    {{ alert.text }}
  </base-material-snackbar>
</template>
<script>
	export default {
		data: () =>({
			alert:{
				active: false,
				type: '',
				text: ''
			}
		}),
		methods:{
			show(alert){
				this.alert = Object.assign(this.alert, alert)
			}
		}
	}
</script>