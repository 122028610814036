<template>
  <v-app>
<!--     <v-overlay color="blue-grey lighten-5" :opacity="1" :value="overlay" :z-index="10">
      <v-progress-circular indeterminate size="60" class="mx-5" color="primary"></v-progress-circular>
      <span class="font-weight-bold d-block text-center pa-5 headline blue-grey--text text--lighten-1">J U V I</span>
    </v-overlay> -->
    <dashboard-core-app-bar />

    <dashboard-core-drawer />
    <dashboard-core-view />


    <confirm ref="confirm"></confirm>
    <alert ref="alert"></alert>
    <v-overlay :value="overlay" :opacity="0.99" :z-index="10" color="white" class="text-center full">
      <v-img
        contain
        max-width="120"
        :src="require('@/assets/Todo-carta-logo.png')"
      ></v-img>
      <v-progress-linear
          color="blue-grey lighten-3"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>
      <!-- <h5 class="blue-grey--text mt-2">Cerrando Sesión</h5> -->
    </v-overlay>
    <div style="position: fixed;right:10px;bottom:10px">
      <v-btn @click="prevRoute()">Atras</v-btn>
    </div>
  </v-app>
</template>

<script>
  import confirm from '@/components/DialogConfirm.vue'
  import alert from '@/components/alert.vue'
  import {
    mapState,
  } from 'vuex'
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      // DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
      confirm,
      alert
    },

    data: () => ({
      expandOnHover: false,

    }),
    methods: {
      prevRoute() {
        this.$router.go(-1)
      }
    },
    mounted(){
      this.$root.$confirm = this.$refs.confirm.open;
      this.$root.$snakbar = this.$refs.alert.show;
    },
    computed:{
      ...mapState(['overlay']),

    },
  }
</script>
