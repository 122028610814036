<template>
   <v-dialog
      v-model="show"
      max-width="350"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
   >
      <v-card>
         <v-toolbar dense flat>
           <v-spacer />
           <v-btn
              :aria-label="$vuetify.lang.t('$vuetify.close')"
              color="blue-grey lighten-1"
              icon
              small
              @click="cancel"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
         </v-toolbar>
         <v-card-text v-show="!!message" class="px-6 pb-6 pt-0">
            <v-row justify-center align-center>
               <v-col cols="12" class="text-center">
                  <div class="v-title__icon_large">
                     <v-icon size="54px" color="deep-orange lighten-2" v-if="options.type == 'delete'">mdi-trash-can-outline</v-icon>                
                     <v-icon size="54px" color="blue-grey" v-if="options.type == 'confirm'">mdi-help-circle-outline</v-icon>                
                  </div>
               </v-col>
            </v-row>
            <v-row justify-center align-center>
               <v-col cols="12" class="text-center pb-0">
                  <span :class="{'black--text':options.type == 'delete' , 'blue-grey--text':options.type == 'confirm' }" style="font-size:18px">{{ title }}</span>
               </v-col>
            </v-row>
            <v-row justify-center align-center>
               <v-col cols="12" class="text-center pt-1">
                  <span class="body-2">{{ message }}</span>
               </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6" class="px-0 pt-1">
                 <v-btn @click.native="agree" :color="colorBtn" block rounded small depressed>{{ options.btnConfirmText }}</v-btn>      
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="4" class="px-0 pt-0 text-center">
                <v-btn @click.native="cancel" color="info" small text rounded>{{ options.btnCancelText }}</v-btn>      
              </v-col>
            </v-row>
         
         </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      type: 'delete',
      btnConfirmText: 'Si',
      btnCancelText: 'Descartar',
    }
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    },
    colorBtn(){
      return this.options.type == "delete" ? 'error' : this.options.type == "confirm" ? 'info' : 'warning';
    }
  },
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>
<style scope>

   .v-title__icon_large .v-icon{
      align-self: center;
       height: 78px;
       min-width: 78px;
   }

.v-title__icon_large .v-icon:after {
    background: currentColor !important;
    border-radius: 50%;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.16;
    position: absolute;
    right: 0;
    top: 0;
}
   
</style>
